.primary-button {
  color: #fff !important;
  padding-right: 2rem !important;
  background: linear-gradient(99.3deg, #4E95FE 4.4%, #20BFF1 104.15%) !important;
  padding-left: 2rem !important;
  font-family: "Poppins" !important;
}

.full-screen-btn {
  width: 100% !important;
}

.primary-button:hover {
  background: linear-gradient(99.3deg, #3673cf 4.4%, #44cbf4 104.15%) !important;
}

.secondary-button {
  background-color: transparent !important;
  color: rgb(32,191,241) !important;
  outline: 1px solid rgb(32,191,241) !important;
  padding-right: 2rem !important;
  padding-left: 2rem !important;
  font-family: "Poppins" !important;
}

.secondary-button > svg:hover{
    color: #fff !important;
}

.secondary-button:hover {
  background: linear-gradient(99.3deg, #4E95FE 4.4%, #20BFF1 104.15%) !important;
  color: #fff !important;
  outline: 1px solid#0077b7 !important;
}

.text-button {
  color: #000 !important;
  padding-right: 2rem !important;
  padding-left: 2rem !important;
  font-family: "Poppins" !important;
}

.medium-button {
  padding: "9px 12px" !important;
  max-height: 2.6rem !important;
}

.large-btn {
  padding: 0.6rem !important;
}

.icon-btn {
  border-radius: 0 !important;
  font-size: 1rem !important;
  color: white;
  font-weight: 500 !important;
  border-radius: 4px !important;
}

.icon-btn > svg {
  margin-right: 0.2rem !important;
}

.icon-btn:hover{
    color: white !important;
}
