.navbar-container {
  width: calc(100% - 10rem);
  position:fixed !important;
  top: 0 !important;
  display: flex;
  align-items: center;
  padding: 0rem 5rem;
  height: 5rem;
  overflow: hidden;
  backdrop-filter: blur(5px);
  z-index: 5;
}
.nav-title {
  flex-basis: 50%;
  /* padding-left: 5rem; */
}
.nav-buttons {
  flex-basis: 50%;
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  align-items: center;
  /* padding-right: 5rem; */
}
.nav-btns {
  display: block;
  display: flex;
}
.nav-menu-icon {
  display: none;
}
.drawer-list-text{
  font-size: 1.2rem !important;
  font-weight: 600 !important;
}
@media (max-width: 576px) {
  .navbar-container {
    padding: 1rem;
    height: auto;
    width: calc(100% - 2rem);
  }
  .nav-menu-icon {
    display: block;
  }
  .nav-btns {
    display: none;
  }
}
