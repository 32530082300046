@font-face {
  font-family: "Bluu Next";
  src: url("https://fonts.cdnfonts.com/css/bluu-next") format("truetype");
}

.cover-letter-app {
  background-color: #f5f5f5;
  /* height: 100vh; */
  /* width: 100vw; */
  overflow-x: hidden;
  height: 100% !important;
}
.styled-title {
  font-family: "Poppins" !important;
  font-weight: 700 !important;
  color: black;
  padding: 0;
}
h1 {
  padding: 0 !important;
  margin: 0 !important;
}
.centered-element {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.column-direction-centered-element {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column !important;
}
.column-direction-justify-centered-element {
  display: flex !important;
  justify-content: center !important;
  flex-direction: column !important;
}
.column-direction-justify-evenly-element {
  display: flex !important;
  justify-content: space-evenly !important;
  flex-direction: column !important;
  flex-wrap: wrap !important;
}
.error-msg {
  font-size: 0.8rem !important;
  color: red !important;
}
.pointer-cursor {
  cursor: pointer;
}
.full-screen-container {
  min-height: 100vh;
}

.d-flex {
  display: flex !important;
}

.head-container-after-login {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  margin: 10rem 0 2rem 0;
}

.disabled {
  opacity: 0.5;
}

.highlighted-text {
  background: linear-gradient(99.3deg, #4e95fe 4.4%, #20bff1 104.15%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

@media (max-width: 899px) {
  .head-container-after-login {
    width: 100%;
  }
}
