.question-box {
  background-color: white;
  padding: 1rem;
  margin: 1rem 0;
}

.linkedin-post-message {
  margin: 0rem 3rem;
  color: #2e2e2e;
  font-size: 0.8rem;
  font-weight: 400;
}
