/* .modal-base{
    z-index: 10 !important;
} */

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 24;
  background-color: #ffff;
  width: 40%;
  padding: 1.2rem;
  border: 1px solid #e0e0e0;
  border-radius: 0.4rem;
  z-index: 10 !important;
}

@media (max-width: 993px) {
  .modal {
    width: 60%;
  }
}

@media (max-width: 576px) {
  .modal {
    width: 70%;
  }
}
