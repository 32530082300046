.featureContainer{
    text-align: center;
    background: linear-gradient(180deg, #F0FAFF 0%, #E8F1FF 100%);
}
.imgBox{
    flex-basis: 40%;
}
.descBox{
    flex-basis: 60%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.featureImg{
    width: 100%;
}

@media (width<=768px){
    .featureImg{
        width: 50%;
        align-self: center;
    }
    .imgBox{
        justify-content: center;
        flex-basis: 40%;
        display: flex;
    }
    .descBox{
        flex-basis: 60%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
    }
    .featureContainer{
        text-align: center;
        background: linear-gradient(180deg, #F0FAFF 0%, #E8F1FF 100%);
    }
}