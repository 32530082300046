.head-input-container {
  display: flex;
  gap: 0.8rem;
}

@media (max-width: 899px) {
  .head-input-container {
    flex-direction: column;
  }
}
