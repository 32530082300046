.card-image{
    /* background-color: #28CBFF; */
    width: 50px;
    height: 50px;
    padding: 10px 10px;
    margin-top: 2rem;
}

.card-grid-container{
    gap: 1rem;
    text-align: center;
    padding: 0px 35px;
}

.card-style{
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    padding: 0rem 20px 1rem 20px;
    border-radius: 5px;
}
.card-style:hover{
    background-color: white;   
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

@media(425px<width<=1024px){
    .card-style{
        width: 50%;
        align-self: center;
    }
}
@media(width<=425px){
    .card-style {
        width: 90%;
        align-self: center;
    }
}