.not-found-page {
  background-color: #f0faff;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  gap: 2rem;
}

.notfoundimg {
  width: 25%;
  height: auto;
}

@media (max-width: 576px) {
  .notfoundimg {
    width: 50%;
  }
}

@media (max-width: 899px) {
  .notfoundimg {
    width: 40%;
  }
}