/* .home-container {
  display: flex;
  align-items: center;
  padding: 0rem 0rem 0rem 5rem;
  height: calc(100% - 5rem);
  height: 200vh;
  margin-top: 5rem;
} */

.home-content {
  flex-basis: 45%;
  height: 100%;
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  /* background-color: aqua; */
  background: url('../../../public/Assets/Ellipse\ 10.svg'), url('../../../public/Assets/Ellipse\ 12.svg'), url('../../../public/Assets/Ellipse\ 11.svg'), url('../../../public/Assets/Ellipse\ 13.svg'), url('../../../public/Assets/Ellipse\ 14.svg'), url('../../../public/Assets/Ellipse\ 15.svg'), url('../../../public/Assets/Ellipse\ 16.svg'), url('../../../public/Assets/Ellipse\ 17.svg');
  background-position: bottom 38rem right 6rem, top 16rem left 8rem, top 7rem left 2rem, top 11rem right 6rem, top 40rem left 3rem, bottom 44rem left 30rem;
  background-repeat: no-repeat;
  background-size: 20%, 20%, 1%, 1%, 1%, 1%, 1%, 1%;
}

.home-image {
  flex-basis: 55%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-image-file {
  max-width: 100%;
  height: auto;
}

.img-announcement-1 {
  position: absolute;
  z-index: 3;
  right: 4rem;
  bottom: 21rem;
  width: 30%;
  filter: drop-shadow(0px 20px 26px rgba(0, 0, 0, 0.09));
  transform: rotate(10.32deg);
}

.img-announcement-2 {
  position: absolute;
  left: 2rem;
  top: 17rem;
  width: 30%;
  filter: drop-shadow(0px 20px 26px rgba(0, 0, 0, 0.09));
  transform: rotate(8.04deg);
}

.announcement-container {
  background-color: #f7f7f7;
  border-radius: 5rem;
  text-align: center;
  height: 100%;
  background-image: url('../../../public/Assets/Ellipse\ 19.svg');
  background-repeat: no-repeat;
  background-position: top 0 left 9rem;
  background-size: 20%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.coverImg{
  width: 100%;
}

.coverly_link{
  text-decoration: none;
  color: white;
}

.coverly_link:hover{
  color: #0077B7;
}

@media (width<=375px) {
  .img-announcement-2 {
    position: absolute;
    left: 0;
    top: 25rem;
    width: 55%;
  }

  .button{
    font-size: 10px;
  }

  .coverImg{
    width: 80%;
  }

  .home-content {
    flex-basis: 45%;
    height: 100%;
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    /* background-color: aqua; */
    background: url('../../../public/Assets/Ellipse\ 10.svg'), url('../../../public/Assets/Ellipse\ 12.svg');
    background-position: top 57rem right 0rem, top 28rem left 0rem;
    background-repeat: no-repeat;
    background-size: 45%, 30%;

  }

  .announcement-container {
    background-color: #f7f7f7;
    border-radius: 5rem;
    text-align: center;
    height: 100%;
    background: none;
    position: relative;
    z-index: 5;
  }

  .img-announcement-1 {
    position: absolute;
    z-index: 3;
    right: 0;
    bottom: 21rem;
    width: 60%;
    /* display: none; */
  }
}
@media (375px<width<=425px) {
  .img-announcement-2 {
    position: absolute;
    left: 0;
    top: 21rem;
    width: 60%;
  }

  .button{
    font-size: 10px;
  }

  .coverImg{
    width: 80%;
  }

  .home-content {
    flex-basis: 45%;
    height: 100%;
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    /* background-color: aqua; */
    background: url('../../../public/Assets/Ellipse\ 10.svg'), url('../../../public/Assets/Ellipse\ 12.svg');
    background-position: top 62rem right 0rem, top 28rem left 0rem;
    background-repeat: no-repeat;
    background-size: 45%, 30%;

  }

  .announcement-container {
    background-color: #f7f7f7;
    border-radius: 5rem;
    text-align: center;
    height: 100%;
    background: none;
    position: relative;
    z-index: 5;
  }

  .img-announcement-1 {
    position: absolute;
    z-index: 3;
    right: 0;
    bottom: 21rem;
    width: 60%;
    /* display: none; */
  }
}

@media (425px<width<=768px) {
  .img-announcement-2 {
    position: absolute;
    left: 0;
    top: 17rem;
    width: 45%;
  }
  .coverImg{
    width: 80%;
  }

  .home-content {
    flex-basis: 45%;
    height: 100%;
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    /* background-color: aqua; */
    background: url('../../../public/Assets/Ellipse\ 10.svg'), url('../../../public/Assets/Ellipse\ 12.svg');
    background-position: top 92rem right 0rem, top 21rem left 0rem;
    background-repeat: no-repeat;
    background-size: 25%, 20%;
  }

  .announcement-container {
    background-color: #f7f7f7;
    border-radius: 5rem;
    text-align: center;
    height: 100%;
    background: none;
    position: relative;
    z-index: 5;
  }

  .img-announcement-1 {
    position: absolute;
    z-index: 3;
    right: 0;
    bottom: 21rem;
    width: 45%;
  }
}


@media (max-width: 993px) {
  .home-container {
    flex-direction: column-reverse;
    align-items: flex-start;
    padding: 0 2rem;
    gap: 2rem;
  }

  .home-content {
    width: 100%;
    justify-content: flex-start;
  }

  .home-image {
    padding-top: 6rem;
  }
}

@media (1024px<width<1200px) {
  .home-content {
    flex-basis: 45%;
    height: 100%;
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    /* background-color: aqua; */
    background: url('../../../public/Assets/Ellipse\ 10.svg'), url('../../../public/Assets/Ellipse\ 12.svg');
    background-position: bottom 96rem right 0rem, top 16rem left 0rem;
    background-repeat: no-repeat;
    background-size: 25%, 20%;
  }
}
@media (1200px<width<1440px) {
  .home-content {
    flex-basis: 45%;
    height: 100%;
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    /* background-color: aqua; */
    background: url('../../../public/Assets/Ellipse\ 10.svg'), url('../../../public/Assets/Ellipse\ 12.svg');
    background-position: bottom 40rem right 0rem, top 16rem left 0rem;
    background-repeat: no-repeat;
    background-size: 25%, 20%;
  }

}
@media (768px<width<=1024px) {
  .home-content {
    flex-basis: 45%;
    height: 100%;
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    /* background-color: aqua; */
    background: url('../../../public/Assets/Ellipse\ 10.svg'), url('../../../public/Assets/Ellipse\ 12.svg');
    background-position: bottom 102rem right 0rem, top 16rem left 0rem;
    background-repeat: no-repeat;
    background-size: 25%, 20%;
  }
  .announcement-container {
    background-color: #f7f7f7;
    border-radius: 5rem;
    text-align: center;
    height: 100%;
    background: none;
    position: relative;
  }
}
