.swiper-button-prev {
    background-image: url(/public/Assets/right-arrow\ 2.svg) !important;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
  }
  
  .swiper-button-next {
    background-image: url(/public/Assets/right-arrow\ 1.svg) !important;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
  }
  
  .swiper-button-next::after {
    display: none;
  }
  
  .swiper-button-prev::after {
    display: none;
  }

  .swiperSlider{
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    /* padding: 2rem 0; */
    background-color: #f0faff;
  }

  @media (425px<width<=768px){
    .swiper-button-prev {
      background-image: url(/public/Assets/right-arrow\ 2.svg) !important;
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-position: center;
      margin-left: 1rem;
    }
    
    .swiper-button-next {
      background-image: url(/public/Assets/right-arrow\ 1.svg) !important;
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-position: center;
      margin-right: 1rem;
    }
  }
  @media (width>768px){
    .swiper-button-prev {
      background-image: url(/public/Assets/right-arrow\ 2.svg) !important;
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-position: center;
      margin-left: 2rem;
    }
    
    .swiper-button-next {
      background-image: url(/public/Assets/right-arrow\ 1.svg) !important;
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-position: center;
      margin-right: 2rem;
    }
  }