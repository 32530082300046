.letter-text-area {
  resize: none;
  min-height: 60vh;
  padding: 0 1.5rem;
  background-color: #FFFFFF;
  font-family: 'Poppins' !important;
  color: #181818;
  font-size: 1.1rem;
  border: 1px solid #E0E0E0;
  border-radius: 0.4rem;
  outline: none;
  /* position: relative; */
}
