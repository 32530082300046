.tuncated-letter {
  /* width: 82%; */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  text-align: justify;
}

.paper-root {
  padding: 1.6rem !important;
  margin-top: 1.2rem !important;
}

@media (max-width: 1201px) {
  .tuncated-letter {
    width: 100%;
  }
}
