.login-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: calc(100% - 5rem); */
  /* background-image: url("/public/Assets/landing-image.svg");
  background-repeat: no-repeat;
  background-position: right; */
}
.login-form-box {
  background-color: #fff;
  text-align: center;
  width: 25%;
  box-shadow: 0px 0px 80px rgba(0, 0, 0, 0.15);
  border-radius: 1.5rem;
  padding: 2rem 3rem 3rem 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
}
.disabled-login-form-box {
  opacity: 0.5;
  pointer-events: none;
}
.user-details-form {
  width: 100% !important;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media (max-width: 576px) {
  .login-form-container {
    padding: 0 2rem;
  }
  .login-form-box {
    width: 100%;
    /* background-color: transparent;
    box-shadow: none; */
  }
}

@media (min-width: 577px) and (max-width: 993px) {
  .login-form-box {
    width: 60%;
  }
}
